<template>
  <component
    :is="to ? NuxtLink : 'div'"
    class="label label-secondary inline-flex h-fit w-fit items-center justify-center gap-2 transition-all duration-300 hover:bg-black-200"
    :class="typeVariations[type || 'white']"
    :to="to"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';
import {NuxtLink} from '#components';
export type tagStyles = 'outline' | 'black' | undefined | null;

defineProps<{
  type?: tagStyles,
  to?: RouteLocationRaw | undefined
}>();


const typeVariations = {
  black : '!bg-neutral-900 !text-white',
  outline: 'label-outline',
  white: '',
};

</script>
